.inputComProfile1 {
  height: 200px;
}

.inputComProfile2 {
  height: 400px;
}

.containerComProfileImage {
  width: 100%;
  height: 80px;
  overflow: hidden;
  text-align: center;
}

.comProfileImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
