.containerLogoHeader {
  width: 100%;
  height: 50px;
  overflow: hidden;
}

.containerLogoHeader img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
