.containerImageMadingPreview {
  height: 400px;
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
}

.containerImageMadingPreview img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
